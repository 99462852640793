<template>
  <div
    class="grid grid-cols-10 text-sm gap-4 p-2 relative"
    :class="index % 2 ? 'bg-gray-200' : null"
  >
    <div v-if="showTriangle(vendor)" class="absolute left-0 ml-2 text-red pulse">
      <font-awesome-icon icon="exclamation-triangle" />
    </div>
    <button
      class="absolute right-1 top-0 text-lg hover:bg-red-300 px-2 py-1 cursor-pointer rounded"
      @click="$emit('deleteVendor', vendor._id)"
    >
      <font-awesome-icon icon="times" />
    </button>
    <div class="row-span-2 flex items-center justify-between">
      <input
        type="checkbox"
        class="mx-4 vendorCheckBox"
        :id="vendor._id"
        @click="$emit('alter', vendor)"
      />
      <DualButton :options="options" @execute="handleDualButton($event)" />
      <!-- <button class="btn-action rounded" @click="$emit('save', vendor)">Save</button> -->
    </div>
    <div class="col-span-2">
      <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
      <div class="mt-1">
        <input
          type="text"
          v-model="vendor.name"
          name="name"
          id="name"
          class="shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
    <div class="col-span-2">
      <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
      <div class="mt-1">
        <input
          type="text"
          v-model="vendor.email"
          name="email"
          id="email"
          class="shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
    <div class="col-span-2">
      <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
      <div class="mt-1">
        <input
          type="text"
          name="phone"
          v-model="vendor.phone"
          id="phone"
          class="shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>

    <div class="col-span-3 flex space-x-4">
      <div>
        <label for="qbID" class="block text-sm font-medium text-gray-700">QB ID</label>
        <div class="mt-1">
          <input
            type="text"
            name="qbID"
            v-model="vendor.quickbooksId"
            id="qbID"
            class="shadow-sm focus:border-blue-500 block w-16 sm:text-sm border-gray-300 rounded-md border px-1"
          />
        </div>
      </div>

      <Incrementor :name="vendor.name" @alterData="alterData" />
    </div>

    <div>
      <label for="tierOne" class="block text-sm font-medium text-gray-700">Tier 1</label>
      <div class="mt-1">
        <input
          type="number"
          name="tierOne"
          v-model="vendor.tierOne"
          id="tierOne"
          class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
    <div>
      <label for="tierTwo" class="block text-sm font-medium text-gray-700">Tier 2</label>
      <div class="mt-1">
        <input
          type="number"
          name="tierTwo"
          v-model="vendor.tierTwo"
          id="tierTwo"
          class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
    <div>
      <label for="tierThree" class="block text-sm font-medium text-gray-700">Tier 3</label>
      <div class="mt-1">
        <input
          type="number"
          name="tierThree"
          v-model="vendor.tierThree"
          id="tierThree"
          class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
    <div>
      <label for="tierFour" class="block text-sm font-medium text-gray-700">Tier 4</label>
      <div class="mt-1">
        <input
          type="number"
          name="tierFour"
          v-model="vendor.tierFour"
          id="tierFour"
          class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
    <div>
      <label for="tierFive" class="block text-sm font-medium text-gray-700">Tier 5</label>
      <div class="mt-1">
        <input
          type="number"
          name="tierFive"
          v-model="vendor.tierFive"
          id="tierFive"
          class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
    <div>
      <label for="tierSix" class="block text-sm font-medium text-gray-700">Tier 6</label>
      <div class="mt-1">
        <input
          type="number"
          name="tierSix"
          v-model="vendor.tierSix"
          id="tierSix"
          class="no-arrows shadow-sm focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md border px-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Incrementor from '@/components/admin/shared/PercentagePriceChange.vue';
import DualButton from '@/components/shared/DualButton';
export default {
  data: function () {
    return {
      options: [
        {
          name: 'Save',
          buttonMethod: 'save',
          text: 'Save this vendor to the database.',
        },
        { name: 'Sync', buttonMethod: 'sync', text: "Sync vendor's products to quickbooks." },
      ],
    };
  },
  props: {
    vendor: Object,
    index: Number,
  },
  methods: {
    handleDualButton(option) {
      switch (option.buttonMethod) {
        case 'save':
          this.$emit('saveVendor', this.vendor);
          break;

        default:
          this.$emit('sync', this.vendor);
          break;
      }
    },
    showTriangle(vendor) {
      if (vendor.quickbooksId) {
        return false;
      }
      return true;
    },
    alterData(payload) {
      this.$store.dispatch('tags/alterVendorPricing', {
        name: payload.data,
        percentage: payload.percentage,
      });
    },
  },
  components: { Incrementor, DualButton },
};
</script>
<style scoped></style>
