<template>
  <div class="min-h-screen md:mx-auto sm:py-4 sm:px-8">
    <div class="flex">
      <Quickbooks :items="selectedVendors" page="Vendors" />
      <button class="px-4 text-primary hover:bg-primary-alt py-1 rounded" @click="addNewVendor">
        + Add Vendor
      </button>
    </div>
    <div class="mt-4 bg-white shadow pb-2 rounded" :key="key">
      <div class="grid grid-cols-7 text-sm gap-4 overflow-hidden bg-primary text-white rounded-t">
        <button class="btn-action" v-if="selectedVendors.length === 0" @click="selectAll">
          Select All
        </button>
        <button class="btn-action" v-else @click="clearVendors">Clear</button>
      </div>
      <div v-for="(vendor, index) in vendors" :key="vendor._id">
        <Vendor
          :vendor="vendor"
          :index="index"
          @alter="alterSelectedVendors"
          @deleteVendor="deleteVendor"
          @saveVendor="saveVendor"
          @sync="syncVendor"
        />
      </div>
    </div>
    <Emailer />
  </div>
</template>

<script>
import Quickbooks from '@/components/Quickbooks.vue';
import Vendor from './Vendor';
import Emailer from './Emailer';
import axios from 'axios';

export default {
  data: function () {
    return {
      selectedVendors: [],
      key: 0,
    };
  },
  props: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
    vendors() {
      return this.$store.getters['tags/getTags']({ type: 'Vendor' });
    },
    admin() {
      return this.$store.getters.getAdmin;
    },
  },
  watch: {},
  methods: {
    addNewVendor() {
      this.vendors.unshift({ name: '', email: '', type: 'Vendor' });
      this.key++;
    },
    clearVendors() {
      this.selectedVendors = new Array();
      const boxes = document.getElementsByClassName('vendorCheckBox');
      boxes.forEach((box) => {
        box.checked = false;
      });
    },
    alterSelectedVendors(vendor) {
      console.log(vendor);
      const checkbox = document.getElementById(vendor._id);
      if (checkbox.checked) {
        checkbox.checked = true;
        this.selectedVendors.push(vendor);
      } else {
        checkbox.checked = false;
        this.selectedVendors = this.selectedVendors.filter((el) => {
          return el._id !== vendor._id;
        });
      }
    },
    deleteVendor(id) {
      if (confirm('Are you sure you wish to delete?')) {
        this.$store.dispatch('tags/deleteTag', id);
      }
    },
    saveVendor(vendor) {
      if (this.admin) {
        //remove a hanging 0x20
        if (vendor.name.slice(-1) == ' ') {
          vendor.name = vendor.name.slice(0, -1);
        }

        this.$store.dispatch('tags/saveVendor', vendor);
      }
    },
    async syncVendor(vendor) {
      try {
        this.$store.commit('setError', {
          message: 'syncing products of the vendor. This may take a few minutes.',
          color: 'green',
        });
        await axios.patch('/api/v1/quickbooks/vendor', vendor);
        this.$store.commit('setError', {
          message: 'successfully synced products',
          color: 'green',
        });
      } catch (error) {
        this.$store.commit('setError', {
          message: 'failed to sync products',
          color: 'red',
        });
      }
    },
    selectAll() {
      this.selectedVendors = this.vendors;
      const boxes = document.getElementsByClassName('vendorCheckBox');
      boxes.forEach((box) => {
        box.checked = true;
      });
    },
  },
  mounted() {},
  components: {
    Quickbooks,
    Vendor,
    Emailer,
  },
};
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
